import React from 'react'
import cx from 'clsx'
import { useLocation } from 'react-router-dom'

import BackButton from '../BackButton'

import styles from './index.module.css'
import { twMerge } from 'tailwind-merge'

export interface HeaderProps {
  title?: string | React.ReactElement
  backLabel?: string
  backUrl?: string
  backAction?: (...args: unknown[]) => unknown
  hideGuidesButton?: boolean
  className?: string
  containerClassName?: string
  buttons?: React.ReactNode
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({
  title,
  backUrl,
  backLabel,
  backAction,
  className,
  containerClassName,
  children,
  buttons,
}) => {
  const { state } = useLocation<{ backRoute?: string }>()

  const backBtnProps = {
    to: !backAction && (!!state?.backRoute || !!backUrl) ? state?.backRoute || backUrl : undefined,
    onClick: backAction,
    label: backLabel,
  }

  const isBackButtonVisible = backUrl || backAction

  return (
    <div className={twMerge(cx(buttons ? styles.headerWithButtons : styles.header, className))}>
      <div className={twMerge(cx(styles.container, containerClassName))}>
        <div id="back-button">
          {isBackButtonVisible && (
            <BackButton className={styles.backButton} data-onboard="request-back" {...backBtnProps} />
          )}
        </div>
        <div
          className={cx(styles.titleContainer, 'justify-center', {
            'md:justify-start': !isBackButtonVisible,
          })}
        >
          {title && <h3 className={styles.title}>{title}</h3>}
        </div>
        {children}
        <div className="flex" id="action-buttons">
          {buttons}
        </div>
      </div>
    </div>
  )
}

export default Header
